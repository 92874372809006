import ErrorPage from "~/components/ErrorPage";

export default function Index() {
  return (
    <ErrorPage
      header="Shop Return Page Not Found"
      message="Please check with your retailer to make sure that the return page link is correct."
    />
  );
}
